import { SearchCarType } from './search';
import { USAStatesEnum } from './states';
import { WithId } from './util';

export enum RequestStatusEnum {
  None = 'none',
  Processing = 'processing',
  Sent = 'sent'
}

export enum UserRoleEnum {
  Member = 'member',
  Manager = 'manager',
  Admin = 'admin'
}

export enum UserCollectionEnum {
  Favorites = 'favorites',
}

export interface FavoriteItemInterface {
  data: SearchCarType;
  date: number;
  status: RequestStatusEnum;
  searchId: string;
  comment?: string;
}

export interface FavoriteItemInterfaceWithId extends FavoriteItemInterface {
  _id: string;
}

export interface UserProfile {
  firstname: string;
  lastname: string;
  phone: string;
  address: string;
  city: string;
  state: USAStatesEnum;
  zipcode: string;
}

export type IUpdateUserProfile = UserProfile

export interface UserSchema {
  email: string;
  uid: string;
  roles: UserRoleEnum[];
  profile: UserProfile;
  isInBlacklist: boolean;
  isNew: boolean;
  createTime: number;
  updateTime: number;
}

export type UserDashboardInfoType = {
  total: number;
  sent: number;
  processing: number;
  ready: number;
}

export type UserSchemaWithId = WithId<UserSchema>

export interface IUserRegister {
  email: string;
  password: string;
  profile: UserProfile;
}

export interface ResetPasswordRequestSchema {
  email: string;
}

export type ResetPasswordRequestSchemaWithId = WithId<ResetPasswordRequestSchema>

export interface CarRequestSchema {
  uid: string;
  email: string;
  createTime: number;
}

export type CarRequestSchemaWithId = WithId<CarRequestSchema>

export enum CarRequestCollectionEnum {
  Cars = 'cars'
}

export type CarRequestItemInterface = FavoriteItemInterface

export interface CarRequestItemWithId extends CarRequestItemInterface {
  id: string;
}
