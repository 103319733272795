import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 16,
    paddingBottom: 16,
    minWidth: 180,
    background: 'rgba(0,0,0,0.3)',
  },
  link: {
    width: '100%',
    color: 'white',
    border: 'none',
    padding: 12,
    paddingLeft: 24,
    background: 'transparent',
    cursor: 'pointer',
    textAlign: 'left',
    '&:hover': {
      background: 'rgba(0,0,0,0.2)',
    },
  },
}));

export default useStyles;
