/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import { firestore, functions } from '@/context/firebase';
import firebase from 'firebase/app';
import {
  SearchCollectionEnum, SearchCarType, SearchSchema, SearchSchemaWithId, MakeType,
} from '@/types/search';
import { QuerySearchParamsType } from '@/types/query';
import APIBase from './base';
import { MetadataDocumentEnum, metadataRef } from './manager';

const searchRef = firestore.collection('search');

class SearchAPI extends APIBase {
  public async create(searchParams: QuerySearchParamsType) {
    functions.httpsCallable('createSearch')(searchParams);
  }

  public async getMakeAndModels() {
    const data = (await metadataRef.doc(MetadataDocumentEnum.MakeAndModels).get()).data()?.value;
    return data as MakeType[] || [];
  }

  public async getById(id: string): Promise<SearchSchema | undefined> {
    const search = await searchRef.doc(id).get();
    if (search.exists) return search.data() as SearchSchema;
    return undefined;
  }

  public async getCar(searchId: string, carId: string): Promise<SearchCarType | undefined> {
    const car = await searchRef.doc(searchId).collection(SearchCollectionEnum.Cars).doc(carId).get();

    if (!car.exists) return undefined;
    return car.data() as SearchCarType;
  }

  public async getCars(id: string, offset = 0, limit = 20) {
    if (!this.auth?.uid) return [];
    const carsSnapshot = await searchRef
      .doc(id)
      .collection(SearchCollectionEnum.Cars)
      .orderBy('index', 'asc')
      .startAt(offset)
      .limit(limit)
      .get();
    return carsSnapshot.docs;
  }

  public async getTotalCarAmount(id: string): Promise<number> {
    if (!this.auth?.uid) return 0;
    const carsLength = (await searchRef.doc(id).collection(SearchCollectionEnum.Cars)
      .get()).docs.length;
    return carsLength;
  }

  public async deleteById(id: string) {
    return searchRef.doc(id).delete();
  }

  public onSnapshot(
    searchId: string,
    cb: (
      (snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => void
    ),
  ): () => void {
    if (this.auth) return searchRef.doc(searchId).onSnapshot((cb));
    return () => {};
  }

  public onUserListSnapshot(
    cb: (
      (queryList: SearchSchemaWithId[]) => void
    ),
  ): () => void {
    if (this.auth?.uid) {
      return searchRef
        .where('uid', '==', this.auth.uid)
        .orderBy('createTime', 'desc')
        .onSnapshot((snapshot) => {
          const queryList = snapshot.docs.map((doc) => ({
            ...(doc.data() as SearchSchema),
            id: doc.id,
          }));
          cb(queryList);
        });
    }
    return () => {};
  }
}

const searchAPI = new SearchAPI();

export default searchAPI;
