const ROUTES = {
  HOME: '/',
  REGISTRATION: '/registration',
  HOW_IT_WORKS: '/how_it_works',
  SEARCH_LIST: '/search',
  FAVORITES: '/favorites',
  FAVORITES_ITEM: '/favorites/:favoriteId',
  USER_PROFILE: '/user',
  SIGN_IN: '/sign_in',
  RESET_PASSWORD: '/reset_password',
  RESET_PASSWORD_WITHOUT_LOGIN: '/reset_password/:resetPasswordRequestId',
  CAR_LIST: '/search/:searchId',
  CAR_INFO: '/search/:searchId/:carId',
  CAR_OF_THE_DAY_INFO: '/car_of_the_day/:carId',
  MANAGER_PANEL_USER: '/manager_panel/user',
  MANAGER_PANEL_USER_PROFILE: '/manager_panel/user/:userId',
  MANAGER_PANEL_QUEUE: '/manager_panel/queue',
  MANAGER_PANEL_REQUEST_LIST: '/manager_panel/request',
  MANAGER_PANEL_CAR_OF_THE_DAY_LIST: '/manager_panel/car_of_the_day',
  MANAGER_PANEL_REQUEST_LIST_PAGE: '/manager_panel/request/:requestId',
  MANAGER_PANEL_REQUEST_LIST_PAGE_ITEM: '/manager_panel/request/:requestId/:carId',
  NOT_FOUND: '/404',
};

export default ROUTES;
