/* eslint-disable no-restricted-globals */
import React from 'react';
import firebase from 'firebase';
import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import CONFIG from '../config';

const firebaseApp = app.initializeApp(
  process.env.REACT_APP_ENV ? CONFIG.firebase[process.env.REACT_APP_ENV] : { projectId: 'dasda' },
);

if (location.hostname === 'localhost') {
  firebase.functions().useEmulator('localhost', 3003);
  firebase.auth().useEmulator('http://localhost:3004');
  firebase.firestore().useEmulator('localhost', 3002);
}

const functions = firebase.functions();
const firestore = firebase.firestore();
const provider = new app.auth.GoogleAuthProvider();

firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

const FirebaseContext = React.createContext({
  functions,
  provider,
  firestore,
  firebase: firebaseApp,
});

export {
  functions,
  provider,
  firestore,
  firebaseApp as firebase,
};

export default FirebaseContext;
