/* eslint-disable class-methods-use-this */
import { AuthType } from '@/types/auth';

export default class APIBase {
  protected auth: AuthType | undefined;

  public setAuth(auth: AuthType | undefined): void {
    this.auth = auth;
  }
}
