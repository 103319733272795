import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  rootLayout: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100vw',
    overflowY: 'hidden',
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    padding: 24,
    maxWidth: 360,
    backgroundColor: '#025669',
    // background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
    boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    '&:focus': {
      outline: 'none',
    },
    '& > h4': {
      margin: 0,
      fontSize: 20,
      color: 'rgba(255, 255, 255, 0.87)',
      lineHeight: '24px',
      marginBottom: 16,
    },
    '& > p': {
      margin: 0,
      fontSize: 16,
      lineHeight: 1.3,
      letterSpacing: '0.25px',
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
}));

export default useStyles;
