import { makeStyles, Theme } from '@material-ui/core/styles';

interface IProps {
  isSmall: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    '&:hover': {
      '& .MuiFormLabel-filled': {
        color: theme.palette.secondary.main,
        '& .multiCount': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  formControlDisabled: {
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },
  inputLabel: {
    color: 'rgba(255, 255, 255, 0.87)',
    left: theme.spacing(1.5),
    top: (props: IProps) => (props.isSmall ? theme.spacing(-0.6) : theme.spacing(-1)),
  },
  inputLabelShrink: {
    top: (props: IProps) => (props.isSmall ? theme.spacing(0.4) : theme.spacing(0.6)),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  inputLabelDisabled: {
    color: 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
  selectMenu: {
    borderRadius: '4px 4px 0px 0px',
    paddingTop: (props: IProps) => (props.isSmall ? theme.spacing(1.2) : theme.spacing(2.4)),
    paddingBottom: (props: IProps) => (props.isSmall ? theme.spacing(1) : theme.spacing(1.2)),
  },
  selectMenuFilled: {
    borderRadius: '4px 4px 0px 0px',
    paddingTop: (props: IProps) => (props.isSmall ? theme.spacing(1.6) : theme.spacing(2.8)),
    paddingBottom: (props: IProps) => (props.isSmall ? theme.spacing(0.6) : theme.spacing(0.8)),
  },
  selectMenuDisabled: {
    backgroundColor: '#2B2D36',
    '&:hover': {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  menuItem: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: (props: IProps) => (props.isSmall ? theme.spacing(0.8) : theme.spacing(1.5)),
    paddingBottom: (props: IProps) => (props.isSmall ? theme.spacing(0.8) : theme.spacing(1.5)),
  },
}));

export default useStyles;
