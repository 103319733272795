import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: 40,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: 100,
    padding: '5px 0',
    marginLeft: 40,
    '&:disabled': {
      backgroundColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #060115',
    },
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;
