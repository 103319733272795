/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import firebase from 'firebase/app';
import { firestore, functions } from '@/context/firebase';
import {
  CarRequestCollectionEnum,
  CarRequestItemInterface,
  CarRequestItemWithId,
  CarRequestSchema, CarRequestSchemaWithId, UserRoleEnum, UserSchema, UserSchemaWithId,
} from '@/types/user';
import _ from 'lodash';
import { CarOfTheDaySchema, CarOfTheDaySchemaWithId, IUpdateCarOfTheDay } from '@/types/carOfTheDay';
import APIBase from './base';

const userRef = firestore.collection('user');
const requestRef = firestore.collection('request');
const carOfTheDayRef = firestore.collection('car_of_the_day');
export const metadataRef = firestore.collection('metadata');

export enum MetadataDocumentEnum {
  ManheimToken = 'manheim_token',
  Queue = 'queue',
  MakeAndModels = 'make_and_models'
}

class ManagerAPI extends APIBase {
  public async getUsers() {
    const query = await userRef.get();
    return query.docs.map((doc) => doc.data() as UserSchema);
  }

  public async getUserById(userId: string): Promise<UserSchema | undefined> {
    const user = await userRef.doc(userId).get();

    if (!user.exists) return undefined;
    const data = user.data() as UserSchema;
    return data;
  }

  public async getQueue() {
    const queue: string[] = await (await metadataRef.doc(MetadataDocumentEnum.Queue).get()).get('value');
    return queue;
  }

  public async removeFromQueue(id: string) {
    await metadataRef.doc(MetadataDocumentEnum.Queue).update({
      value: firebase.firestore.FieldValue.arrayRemove(id),
    });
  }

  public async updateQueue(queryId: string, direction: 'up' | 'down' | 'start' | 'end'): Promise<void> {
    const queueDoc = metadataRef.doc(MetadataDocumentEnum.Queue);
    let queue: string[] = (await queueDoc.get()).get('value');

    const queryIndex = queue.findIndex((id) => id === queryId);

    if (['up', 'down'].includes(direction)) {
      let buffer: string | undefined;
      let bufferIndex: number | undefined;
      if (direction === 'down') {
        bufferIndex = queryIndex - 1;
        buffer = queue[bufferIndex];
      } else {
        bufferIndex = queryIndex + 1;
        buffer = queue[bufferIndex];
      }

      if (buffer !== undefined) {
        queue[queryIndex] = buffer;
        queue[bufferIndex] = queryId;
      }
    }

    if (['start', 'end'].includes(direction)) {
      queue = queue.filter((id) => id !== queryId);
      if (direction === 'start') queue.unshift(queryId);
      else queue.push(queryId);
    }

    await queueDoc.update({
      value: queue,
    });
  }

  public async updateUserRole(uid: string, role: UserRoleEnum, state: boolean) {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error(`No permission to update role: ${role}`);

    if (role === UserRoleEnum.Member) {
      return userRef.doc(uid).update({
        roles: state ? firebase.firestore.FieldValue.arrayUnion(UserRoleEnum.Member) : firebase.firestore.FieldValue.arrayRemove(UserRoleEnum.Member),
        isNew: false,
      });
    }

    if (role === UserRoleEnum.Manager && _.intersection(this.auth?.roles, [UserRoleEnum.Admin]).length) {
      return userRef.doc(uid).update({
        roles: state ? firebase.firestore.FieldValue.arrayUnion(UserRoleEnum.Manager) : firebase.firestore.FieldValue.arrayRemove(UserRoleEnum.Manager),
        isNew: false,
      });
    }

    if (role === UserRoleEnum.Admin && _.intersection(this.auth?.roles, [UserRoleEnum.Admin]).length) {
      return userRef.doc(uid).update({
        roles: state ? firebase.firestore.FieldValue.arrayUnion(UserRoleEnum.Admin) : firebase.firestore.FieldValue.arrayRemove(UserRoleEnum.Admin),
        isNew: false,
      });
    }
    throw new Error('Something went wrong!');
  }

  public async deleteUser(id: string) {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to delete users');
    if (this.auth.uid === id) throw new Error('You cant delete yourself');

    await userRef.doc(id).delete();
  }

  public async activateUser(id: string) {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to activate users');
    if (this.auth.uid === id) throw new Error('You cant delete yourself');

    await userRef.doc(id).update({
      isInBlacklist: false,
      roles: firebase.firestore.FieldValue.arrayUnion(UserRoleEnum.Member),
    });
  }

  public async updateUserBlacklistState(id: string, state: boolean) {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to add users to blacklist');
    if (this.auth.uid === id) throw new Error('You cant update yourself');

    await userRef.doc(id).update({
      isInBlacklist: state,
    });
  }

  public async deleteRequest(id: string) {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to get requests');

    await requestRef.doc(id).delete();
  }

  public async deleteRequestCar(requestId: string, id: string) {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to get requests');

    await requestRef
      .doc(requestId)
      .collection(CarRequestCollectionEnum.Cars)
      .doc(id)
      .delete();
  }

  public async getRequest(id: string) {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to get requests');

    const requestDoc = await requestRef.doc(id).get();
    if (!requestDoc.exists) return undefined;

    const request: CarRequestSchemaWithId = {
      ...(requestDoc.data() as CarRequestSchema),
      id: requestDoc.id,
    };

    return request;
  }

  public async getAllRequests() {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to get requests');

    const requestDocList = await requestRef.get();

    const requestList: CarRequestSchema[] = requestDocList.docs.map((doc) => ({
      ...(doc.data() as CarRequestSchema),
      id: doc.id,
    }));

    return requestList;
  }

  public async getRequestCars(requestId: string): Promise<CarRequestItemWithId[]> {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to get requests');

    const requestDocList = await requestRef.doc(requestId).collection(CarRequestCollectionEnum.Cars).get();

    const requestList: CarRequestItemWithId[] = requestDocList.docs.map((doc) => ({
      ...(doc.data() as CarRequestItemInterface),
      id: doc.id,
    }));

    return requestList;
  }

  public async getRequestCar(requestId: string, carId: string): Promise<CarRequestItemWithId> {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to get requests');

    const carDoc = await requestRef.doc(requestId).collection(CarRequestCollectionEnum.Cars).doc(carId).get();

    const car: CarRequestItemWithId = {
      ...(carDoc.data() as CarRequestItemInterface),
      id: carDoc.id,
    };

    return car;
  }

  public async createCarOfTheDay(vehicleUrl: string) {
    await functions.httpsCallable('createCarOfTheDay')(vehicleUrl);
  }

  public async getCarOfTheDay(carId: string): Promise<CarOfTheDaySchemaWithId> {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to get cars of the day');

    const carDoc = await carOfTheDayRef.doc(carId).get();

    const car: CarOfTheDaySchemaWithId = {
      ...(carDoc.data() as CarOfTheDaySchema),
      id: carDoc.id,
    };

    return car;
  }

  public async updateCarOfTheDay(carId: string, data: IUpdateCarOfTheDay): Promise<void> {
    if (!this.auth || !_.intersection(this.auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) throw new Error('No permission to get cars of the day');

    await carOfTheDayRef.doc(carId).update(data);
  }

  public onRequestCarsSnapshot(requestId: string, cb: (carList: CarRequestItemWithId[]) => void) {
    return requestRef
      .doc(requestId)
      .collection(CarRequestCollectionEnum.Cars)
      .onSnapshot((snapshot) => {
        const carList = snapshot.docs.map((doc) => {
          const data: CarRequestItemWithId = {
            id: doc.id,
            ...(doc.data() as CarRequestItemInterface),
          };
          return data;
        });

        cb(carList);
      });
  }

  public onAllRequestsSnapshot(cb: (carList: CarRequestSchemaWithId[]) => void) {
    return requestRef
      .onSnapshot((snapshot) => {
        const requestList = snapshot.docs.map((doc) => {
          const data: CarRequestSchemaWithId = {
            id: doc.id,
            ...(doc.data() as CarRequestSchema),
          };
          return data;
        });

        cb(requestList);
      });
  }

  public onNewUsersSnapshot(cb: (userList: UserSchemaWithId[]) => void) {
    return userRef
      .where('isNew', '==', true)
      .where('isInBlacklist', '==', false)
      .onSnapshot((snapshot) => {
        const UserList = snapshot.docs.map((doc) => {
          const data: UserSchemaWithId = {
            id: doc.id,
            ...(doc.data() as UserSchema),
          };
          return data;
        });

        cb(UserList);
      });
  }

  public onBlacklistUsersSnapshot(cb: (userList: UserSchemaWithId[]) => void) {
    return userRef.where('isInBlacklist', '==', true).onSnapshot((snapshot) => {
      const UserList = snapshot.docs.map((doc) => {
        const data: UserSchemaWithId = {
          id: doc.id,
          ...(doc.data() as UserSchema),
        };
        return data;
      });

      cb(UserList);
    });
  }

  public onAllUsersSnapshot(cb: (userList: UserSchemaWithId[]) => void) {
    return userRef.onSnapshot((snapshot) => {
      const UserList = snapshot.docs.map((doc) => {
        const data: UserSchemaWithId = {
          id: doc.id,
          ...(doc.data() as UserSchema),
        };
        return data;
      });

      cb(UserList);
    });
  }

  public onQueueSnapshot(cb: (queue: string[]) => void) {
    return metadataRef
      .doc(MetadataDocumentEnum.Queue)
      .onSnapshot((snapshot) => {
        const queue: string[] = snapshot.get('value');
        cb(queue || []);
      });
  }

  public onAllCarOfTheDaySnapshot(cb: (carList: CarOfTheDaySchemaWithId[]) => void) {
    return carOfTheDayRef
      .onSnapshot((snapshot) => {
        const carList = snapshot.docs.map((doc) => {
          const data: CarOfTheDaySchemaWithId = {
            id: doc.id,
            ...(doc.data() as CarOfTheDaySchema),
          };
          return data;
        });

        cb(carList);
      });
  }

  public onActiveCarOfTheDayListSnapshot(cb: (carList: CarOfTheDaySchemaWithId[]) => void) {
    return carOfTheDayRef
      .where('isActive', '==', true)
      .onSnapshot((snapshot) => {
        const carList = snapshot.docs.map((doc) => {
          const data: CarOfTheDaySchemaWithId = {
            id: doc.id,
            ...(doc.data() as CarOfTheDaySchema),
          };
          return data;
        });

        cb(carList);
      });
  }
}

const managerAPI = new ManagerAPI();

export default managerAPI;
