/* eslint-disable max-len */
import firebase from 'firebase/app';
import { firestore, functions } from '@/context/firebase';
import {
  FavoriteItemInterface, IUserRegister, RequestStatusEnum, ResetPasswordRequestSchema, ResetPasswordRequestSchemaWithId, UserCollectionEnum, UserDashboardInfoType, UserProfile, UserSchema,
} from '@/types/user';
import { QueueItemStatusEnum } from '@/types/query';
import SearchAPI from './search';
import APIBase from './base';

const userRef = firestore.collection('user');
const queryRef = firestore.collection('query');
const requestRef = firestore.collection('request');
const resetPasswordRequestRef = firestore.collection('reset_password_request');

class UserAPI extends APIBase {
  // eslint-disable-next-line class-methods-use-this
  public async register(data: IUserRegister): Promise<void> {
    await functions.httpsCallable('registerUser')(data);
  }

  public async getProfile(): Promise<UserProfile | undefined> {
    if (!this.auth) return undefined;

    const user = await userRef.doc(this.auth.uid).get();
    if (!user.exists) return undefined;

    return (user.data() as UserSchema).profile;
  }

  public async setProfile(profile: UserProfile): Promise<void> {
    if (!this.auth) return;
    await userRef.doc(this.auth?.uid).update({
      profile,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async resetPassword(resetPasswordRequestId: string, password: string) {
    await functions.httpsCallable('resetPassword')({
      resetPasswordRequestId,
      password,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async createResetPasswordRequest(email: string) {
    await functions.httpsCallable('createResetPasswordRequest')({
      email,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getResetPasswordRequest(resetPasswordRequestId: string): Promise<ResetPasswordRequestSchemaWithId | undefined> {
    const resetPasswordRequestDoc = await resetPasswordRequestRef.doc(resetPasswordRequestId).get();
    if (!resetPasswordRequestDoc.exists) return undefined;

    const data: ResetPasswordRequestSchemaWithId = {
      id: resetPasswordRequestDoc.id,
      ...(resetPasswordRequestDoc.data() as ResetPasswordRequestSchema),
    };

    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  public async getDashboardInfo(): Promise<UserDashboardInfoType> {
    if (!this.auth) {
      return {
        total: 0,
        sent: 0,
        processing: 0,
        ready: 0,
      };
    }
    const [
      requestSnapshot,
      querySnapshot,
    ] = await Promise.all([
      requestRef
        .where('uid', '==', this.auth.uid)
        .get(),
      queryRef
        .where('uid', '==', this.auth.uid)
        .get(),
    ]);

    const data: UserDashboardInfoType = {
      sent: requestSnapshot.docs.length,
      total: 0,
      processing: 0,
      ready: 0,
    };

    querySnapshot.docs.forEach((query) => {
      const { status } = query.data();
      data.total += 1;
      if (status === QueueItemStatusEnum.Processing) data.processing += 1;
      else if (status === QueueItemStatusEnum.Ready) data.ready += 1;
    });

    return data;
  }

  public async getFavoriteItem(carId: string): Promise<FavoriteItemInterface | undefined> {
    if (!this.auth?.uid) return undefined;

    const favorite = await userRef.doc(this.auth.uid).collection(UserCollectionEnum.Favorites).doc(carId).get();

    if (!favorite.exists) return undefined;

    return favorite.data() as FavoriteItemInterface;
  }

  public async getFavorites() {
    if (!this.auth?.uid) return [];
    const favorites = (await userRef.doc(this.auth.uid).collection(UserCollectionEnum.Favorites).get()).docs;
    return favorites;
  }

  public async addFavorite(searchId: string, carId: string) {
    const carData = await SearchAPI.getCar(searchId, carId);
    if (!carData || !this.auth?.uid) return;

    const favorite = await userRef.doc(this.auth.uid).collection(UserCollectionEnum.Favorites).doc(carId);

    const newFavorite: FavoriteItemInterface = {
      date: firebase.firestore.Timestamp.now().toMillis(),
      status: RequestStatusEnum.None,
      searchId,
      data: carData,
    };

    await favorite.set(newFavorite);
  }

  public async deleteFavorite(carId: string): Promise<void> {
    if (!this.auth?.uid) return;

    const favorite = await userRef.doc(this.auth.uid).collection(UserCollectionEnum.Favorites).doc(carId);
    await favorite.delete();
  }

  public async deleteFavoriteByIdList(idList: Array<string>): Promise<void> {
    await Promise.all(
      idList.map((id) => this.deleteFavorite(id)),
    );
  }

  public async createRequest(idList: string[]) {
    if (!this.auth) return;
    await functions.httpsCallable('createRequest')(idList);
  }

  public async setFavoriteComment(carId: string, value: string) {
    if (!this.auth?.uid || !carId) return;

    const favorite = await userRef.doc(this.auth.uid).collection(UserCollectionEnum.Favorites).doc(carId);
    await favorite.update({
      comment: value,
    });
  }

  public onSnapshot(cb: ((user: UserSchema) => void)): () => void {
    return userRef.doc(this.auth?.uid).onSnapshot((snapshot) => {
      const data: UserSchema = snapshot.data() as UserSchema;
      cb(data);
    });
  }

  public onFavoriteSnapshot(favoriteId: string, cb: ((snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => void)): () => void {
    return userRef.doc(this.auth?.uid).collection(UserCollectionEnum.Favorites).doc(favoriteId).onSnapshot(cb);
  }

  public onFavoritesSnapshot(cb: ((snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void)): () => void {
    return userRef.doc(this.auth?.uid).collection(UserCollectionEnum.Favorites).onSnapshot(cb);
  }
}

const userAPI = new UserAPI();

export default userAPI;
