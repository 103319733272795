import React, {
  FC, useState, useContext, useCallback, useEffect,
} from 'react';

import { Notification } from '@/ui';
import {
  NotificationContext, SearchContext, AuthContext, ModalContext, FirebaseContext,
} from '@/context';

import { initialState as modalInitialState } from '@/constants/modal';

import firebase from 'firebase/app';

import APIController from '@/api';
import { Modal } from '@material-ui/core';
import { AuthType } from '@/types/auth';
import { MakeType } from '@/types/search';
import SearchAPI from '@/api/search';
import Header from './components/Header';
import useStyles from './styles';

const Layout: FC = ({ children }) => {
  const classes = useStyles();

  const [searchFirst] = useContext(SearchContext);
  const [authFirst] = useContext(AuthContext);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState(searchFirst);
  const [auth, setAuth] = useState<AuthType | undefined>(authFirst);
  const [makeData, setMakeData] = useState<MakeType[]>([]);

  const { firebase: firebaseApp } = useContext(FirebaseContext);
  const [modalInfo, setModal] = useState<{title?: string; text: string}>(modalInitialState);

  const handleUpdateAuth = useCallback(async (user: firebase.User | null) => {
    if (user?.uid) {
      const authData = await APIController.updateAuth(user.uid);
      if (authData?.roles.length && !authData.isInBlacklist) setAuth(authData);
      else {
        setError('No permission!');
        firebase.auth().signOut();
      }
    } else {
      APIController.resetAuth();
      setAuth(undefined);
    }
  }, []);

  useEffect(() => {
    const unWatchAuth = firebaseApp.auth().onAuthStateChanged(handleUpdateAuth);
    return () => {
      unWatchAuth();
    };
  }, [firebaseApp, handleUpdateAuth]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') console.log('Auth: ', auth);
  }, [auth]);

  useEffect(() => {
    SearchAPI.getMakeAndModels().then(setMakeData);
  }, []);

  return (
    <main className={classes.rootLayout}>
      <ModalContext.Provider value={[modalInfo, setModal]}>
        <NotificationContext.Provider value={{
          success, error, setSuccess: handleSetSuccess, setError: handleSetError,
        }}
        >
          <AuthContext.Provider value={[auth, setAuth]}>
            <SearchContext.Provider value={[search, setSearch]}>
              <Header makeData={makeData} />
              <div>
                { children }
              </div>
            </SearchContext.Provider>
          </AuthContext.Provider>
        </NotificationContext.Provider>
      </ModalContext.Provider>

      <Notification
        isOpen={!!success}
        message={success || ''}
        onClose={handleCloseSuccess}
        type="success"
        delay={5000}
      />
      <Notification
        isOpen={!!error}
        message={error || ''}
        onClose={handleCloseError}
        type="error"
        delay={5000}
      />
      <Modal
        open={!!(modalInfo.title || modalInfo.text)}
        onClose={handleCloseModal}
        className={classes.modalContainer}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modal}>
          {
            !!modalInfo.title && <h4>{modalInfo.title}</h4>
          }
          <p>{modalInfo.text}</p>
        </div>
      </Modal>
    </main>
  );

  function handleCloseSuccess() {
    setSuccess(null);
  }

  function handleCloseError() {
    setError(null);
  }

  function handleCloseModal() {
    setModal(modalInitialState);
  }

  function handleSetSuccess(value: string) {
    setSuccess(null);
    setSuccess(value);
  }

  function handleSetError(value: string) {
    setError(null);
    setError(value);
  }
};

export default Layout;
