/* eslint-disable no-mixed-operators */
import { AuthType } from '@/types/auth';
import { firestore, firebase } from '@/context/firebase';
import QueryAPI from './query';
import SearchAPI from './search';
import UserAPI from './user';
import ManagerAPI from './manager';

const userRef = firestore.collection('user');

class APIController {
  static setAuth(auth: AuthType): void {
    QueryAPI.setAuth(auth);
    SearchAPI.setAuth(auth);
    UserAPI.setAuth(auth);
    ManagerAPI.setAuth(auth);
  }

  static resetAuth(): void {
    QueryAPI.setAuth(undefined);
    SearchAPI.setAuth(undefined);
    UserAPI.setAuth(undefined);
    ManagerAPI.setAuth(undefined);
  }

  static async fetchAuth(uid: string): Promise<AuthType | undefined> {
    const user = await firebase.auth().currentUser?.getIdTokenResult();
    const firestoreUser = await (await userRef.doc(uid).get()).data();

    if (!user || !firestoreUser) return undefined;

    if (!firestoreUser.roles.length) return undefined;

    if (firestoreUser.isInBlacklist) return undefined;

    const authData: AuthType = {
      email: firestoreUser.email || user.claims.email,
      uid: firestoreUser.uid || user.claims.user_id,
      profile: firestoreUser.profile || user.claims.profile,
      roles: firestoreUser.roles || user.claims.roles,
      isNew: firestoreUser.isNew || user.claims.isNew,
      isInBlacklist: firestoreUser.isInBlacklist || user.claims.isInBlacklist,
    };
    return authData;
  }

  static async updateAuth(uid: string): Promise<AuthType | undefined> {
    const authData = await this.fetchAuth(uid);
    if (!authData) {
      this.resetAuth();
      return undefined;
    }
    this.setAuth(authData);
    return authData;
  }
}

export default APIController;
