import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';

interface IProps {
  isLoading: boolean;
  size?: number;
}

const LoadingCircle: FC<IProps> = ({ isLoading, ...restProps }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!!isLoading && <CircularProgress {...restProps} disableShrink color="secondary" />}
    </div>
  );
};

export default LoadingCircle;
