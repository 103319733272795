/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { initialState } from '@/constants/search';

const context: any = [
  initialState,
  () => null,
];

const SearchContext = React.createContext<any>(context);

export default SearchContext;
