/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

const NotificationContext = React.createContext<Partial<any>>({
  success: null,
  error: null,
  setSuccess: () => null,
  setError: null,
});

export default NotificationContext;
