import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import App from '@/app';
import theme from '@/theme';
import * as serviceWorker from '@/serviceWorker';
import FirestoreContext, {
  functions, provider, firebase, firestore,
} from './context/firebase';

const Component = () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <FirestoreContext.Provider value={{
          firestore,
          functions,
          provider,
          firebase,
        }}
        >
          <App />
        </FirestoreContext.Provider>
      </HelmetProvider>
    </ThemeProvider>
  </BrowserRouter>
);

serviceWorker.unregister();
ReactDOM.render(<Component />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./app', () => {
    ReactDOM.render(<Component />, document.getElementById('root'));
  });
}
