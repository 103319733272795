import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Popover from '@material-ui/core/Popover';

import FavoritesIcon from '@/assets/menu/favorites.svg';
import HistoryIcon from '@/assets/menu/history.svg';
import HowItWorksIcon from '@/assets/menu/how_it_works.svg';
import SearchIcon from '@/assets/menu/search.svg';
import SignInIcon from '@/assets/menu/sign_in.svg';
import ManagerPanelIcon from '@/assets/menu/manager_panel.svg';
import ProfileIcon from '@/assets/menu/profile.svg';

import firebase from 'firebase/app';
import { useHistory } from 'react-router';
import AuthContext from '@/context/auth';
import { UserRoleEnum } from '@/types/user';
import ROUTES from '@/constants/routes';
import _ from 'lodash';
import { useResize } from '@/utils';
import { MakeType } from '@/types/search';
import useStyles from './style';
import Search from '../Search';
import Aside from '../Aside';

type HeaderProps = {
  makeData: MakeType[];
}

const Header: FC<HeaderProps> = ({
  makeData,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [auth] = useContext(AuthContext);

  const { width } = useResize();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleLogOut = useCallback(() => {
    history.replace(ROUTES.HOME);
    firebase.auth().signOut();
  }, [history]);

  const handleGo = useCallback((path: string) => () => {
    history.push(path);
  }, [history]);

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenPopover = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  return (
    <header className={classes.root}>
      <Container className={classes.content}>
        <button type="button" className={classes.logo} onClick={handleGo(ROUTES.HOME)}>Skipadealer</button>
        {
          width >= 1200 && (auth ? (
            <div className={classes.controls}>
              <div className={classes.controlItemContainer}>
                {
                  !!_.intersection(auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length && (
                    <div className={classes.controlItem}>
                      <Button onClick={handleGo(ROUTES.MANAGER_PANEL_USER)}>
                        <img src={ManagerPanelIcon} alt="" />
                        <span>Manager panel</span>
                      </Button>
                    </div>
                  )
                }
                <div className={classes.controlItem}>
                  <Button onClick={handleGo(ROUTES.HOW_IT_WORKS)}>
                    <img src={HowItWorksIcon} alt="" />
                    <span>How it works</span>
                  </Button>
                </div>
                <div className={classes.controlItem}>
                  <Button onClick={handleGo(ROUTES.SEARCH_LIST)}>
                    <img src={HistoryIcon} alt="" />
                    <span>History</span>
                  </Button>
                </div>
                <div className={classes.controlItem}>
                  <Button onClick={handleGo(ROUTES.FAVORITES)}>
                    <img src={FavoritesIcon} alt="" />
                    <span>Favorites</span>
                  </Button>
                </div>
                <Popover
                  open={Boolean(anchorEl)}
                  onClose={handleClosePopover}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  className={classes.searchWrap}
                >
                  <Search
                    className={classes.search}
                    closePopup={handleClosePopover}
                    makeData={makeData}
                  />
                </Popover>
                <div className={classes.controlItem}>
                  <Button onClick={handleOpenPopover}>
                    <img src={SearchIcon} alt="" />
                    <span>Search</span>
                  </Button>
                </div>
                <div className={classes.controlItem}>
                  <Button onClick={handleGo(ROUTES.USER_PROFILE)}>
                    <img src={ProfileIcon} alt="" />
                    <span>Profile</span>
                  </Button>
                </div>
                <div className={classes.controlItem}>
                  <Button onClick={handleLogOut}>
                    <img src={SignInIcon} alt="" />
                    <span>Sign out</span>
                  </Button>
                </div>
              </div>
              {/* <span className={classes.email}>{email}</span> */}
            </div>
          ) : (
            <div className={classes.controls}>
              <div className={classes.controlItemContainer}>
                <div className={classes.controlItem}>
                  <Button onClick={handleGo(ROUTES.HOW_IT_WORKS)}>
                    <img src={HowItWorksIcon} alt="" />
                    <span>How it works</span>
                  </Button>
                </div>
                <div className={classes.controlItem}>
                  <Button onClick={handleGo(ROUTES.SIGN_IN)}>
                    <img src={SignInIcon} alt="" />
                    <span>Sign In</span>
                  </Button>
                </div>
              </div>
            </div>
          ))
        }
        {
          width < 1200 && <Aside makeData={makeData} onGo={handleGo} onLogOut={handleLogOut} />
        }
      </Container>
    </header>
  );
};

export default Header;
