const CONFIG = {
  firebase: {
    development: {
      projectId: 'skipadealer-e5db4',
      storageBucket: 'skipadealer-e5db4.appspot.com',
      apiKey: 'AIzaSyA9WwK4GtVF2KF9FyKdhcyZh-uzeV5lpt0',
      authUri: 'https://accounts.google.com/o/oauth2/auth',
      tokenUri: 'https://oauth2.googleapis.com/token',
      authDomain: 'skipadealer-e5db4.firebaseapp.com',
      databaseURL: 'https://skipadealer-e5db4.firebaseio.com',
    },
    production: {
      projectId: 'skipadealer-prod-5dc85',
      storageBucket: 'skipadealer-prod-5dc85.appspot.com',
      apiKey: 'AIzaSyCbPRPWJ1mHxCwZIpc-NxxUn7vLwu9E5Zs',
      authUri: 'https://accounts.google.com/o/oauth2/auth',
      tokenUri: 'https://oauth2.googleapis.com/token',
      authDomain: 'skipadealer-prod-5dc85.firebaseapp.com',
      databaseURL: 'https://skipadealer-prod-5dc85.firebaseio.com',
    },
  },
};

export default CONFIG;
