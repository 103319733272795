import { CssBaseline } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router';
import React, { FC, lazy, Suspense } from 'react';

import '@/theme/global.scss';
import ROUTES from '@/constants/routes';
// import { ProtectedRoute } from '@/utils/components';
import { LoadingCircle } from '@/ui';
import { ProtectedRoute } from '@/utils/components/ProtectedRoute';
import { Layout } from './Layout';
import { ManagerLayout } from './ManagerLayout';

const HomePage = lazy(() => import('./Home'));
const HowItWorksPage = lazy(() => import('./HowItWorks'));
const QueryListPage = lazy(() => import('./SearchList'));
const FavoritesPage = lazy(() => import('./Favorites'));
const CarListPage = lazy(() => import('./CarList'));
const CarInfoPage = lazy(() => import('./CarInfo'));
const UserProfilePage = lazy(() => import('./UserProfile'));
const RegistrationPage = lazy(() => import('./Registration'));
const UserManagementPage = lazy(() => import('./UserManagement'));
const QueueManagementPage = lazy(() => import('./QueueManagement'));
const RequestManagementPage = lazy(() => import('./RequestManagement'));
const CarOfTheDayManagementPage = lazy(() => import('./CarOfTheDayManagement'));
const RequestCarsPage = lazy(() => import('./RequestCars'));
const SignInPage = lazy(() => import('./SignIn'));
const ResetPasswordPage = lazy(() => import('./ResetPassword'));
const NotFoundPage = lazy(() => import('./NotFound'));

const App: FC = () => (
  <>
    <CssBaseline />
    <Helmet>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
    </Helmet>
    <Layout>
      <Suspense fallback={(
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '50vh',
        }}
        >
          <LoadingCircle isLoading size={64} />
        </div>
      )}
      >
        <Switch>
          <Route path={ROUTES.NOT_FOUND} exact component={NotFoundPage} />
          <Route path={ROUTES.REGISTRATION} exact component={RegistrationPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.RESET_PASSWORD_WITHOUT_LOGIN} exact component={ResetPasswordPage} />
          <Route path={ROUTES.RESET_PASSWORD} exact component={ResetPasswordPage} />
          <Route path={ROUTES.HOME} exact component={HomePage} />
          <Route path={ROUTES.HOW_IT_WORKS} exact component={HowItWorksPage} />
          <ProtectedRoute path={ROUTES.USER_PROFILE} exact component={UserProfilePage} />
          <ProtectedRoute path={ROUTES.SEARCH_LIST} exact component={QueryListPage} />
          <ProtectedRoute path={ROUTES.FAVORITES_ITEM} exact>
            <CarInfoPage isFavoritePage />
          </ProtectedRoute>
          <ProtectedRoute path={ROUTES.FAVORITES} exact component={FavoritesPage} />
          <ProtectedRoute path={ROUTES.CAR_LIST} exact component={CarListPage} />
          <ProtectedRoute path={ROUTES.CAR_INFO} exact>
            <CarInfoPage />
          </ProtectedRoute>
          <ProtectedRoute path={ROUTES.CAR_OF_THE_DAY_INFO} exact>
            <CarInfoPage isCarOfTheDayPage />
          </ProtectedRoute>
          <ManagerLayout>
            <Route
              path={ROUTES.MANAGER_PANEL_USER}
              exact
              component={UserManagementPage}
            />
            <Route
              path={ROUTES.MANAGER_PANEL_USER_PROFILE}
              exact
            >
              <UserProfilePage isFromManagerPanel />
            </Route>
            <Route
              path={ROUTES.MANAGER_PANEL_QUEUE}
              exact
              component={QueueManagementPage}
            />
            <Route
              path={ROUTES.MANAGER_PANEL_REQUEST_LIST}
              exact
              component={RequestManagementPage}
            />
            <Route
              path={ROUTES.MANAGER_PANEL_CAR_OF_THE_DAY_LIST}
              exact
              component={CarOfTheDayManagementPage}
            />
            <Route
              path={ROUTES.MANAGER_PANEL_REQUEST_LIST_PAGE}
              exact
              component={RequestCarsPage}
            />
            <Route
              path={ROUTES.MANAGER_PANEL_REQUEST_LIST_PAGE_ITEM}
              exact
            >
              <CarInfoPage isRequestPage />
            </Route>
          </ManagerLayout>
          <ProtectedRoute path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </Layout>
  </>
);

export default App;
