import ROUTES from '@/constants/routes';
import { AuthContext } from '@/context';
import React, {
  FC, useContext,
} from 'react';
import {
  Redirect,
  Route,
  RouteProps,
} from 'react-router';

const ProtectedRoute: FC<RouteProps> = ({ ...rest }) => {
  const [auth] = useContext(AuthContext);

  return (
    auth
      ? (
        <Route
          {...rest}
        />
      ) : <Redirect to={ROUTES.SIGN_IN} />
  );
};

export default ProtectedRoute;
