/* eslint-disable import/prefer-default-export */
import makeList from './make.json';

const make = makeList[0];
const model = make.models[0];

// eslint-disable-next-line import/prefer-default-export
const nowYear = (new Date()).getFullYear();

export const initialState = {
  fromYear: `${nowYear - 1}`,
  toYear: `${nowYear}`,
  make: make.value,
  model,
};
