import { QuerySearchParamsType, QueueItemStatusEnum } from './query';

export type ModelType = {
    value: string;
    name: string;
}
export type MakeType = {
    value: string;
    name: string;
    models: ModelType[];
}

export enum TransmissionEnum { Handle = 'Handle', Automatic = 'Automatic' }

export type VehicleSpecificationType = {
    'Year'?: string;
    'Make'?: string;
    'Model'?: string;
    'Trim Level'?: string;
    'RSPEC Code'?: string;
    'Odometer'?: string;
    'In-Service Date'?: string;
    'Fuel Type'?: string;
    'Engine'?: string;
    'Displacement'?: string;
    'Transmission'?: string;
    'Exterior Color'?: string;
    'Interior Color'?: string;
    'Window Sticker'?: string;
    'VIN'?: string;
    'Body Style'?: string;
    'Doors'?: string;
    'Vehicle Type'?: string;
    'Salvage'?: string;
    'As Is'?: string;
    'Title State'?: string;
    'Title Status'?: string;
    'Drive Train'?: string;
    'Interior Type'?: string;
    'Top Type'?: string;
    'Stereo'?: string;
    'Airbags'?: string;
}

export type SearchCarDataType = {
    title: string;
    photoFrames: string[];

    saleTimeLeft: string;
    saleStartDate: string;
    saleEndDate: string;
    saleFacilitation: string;

    conditionSearchUrl?: string | undefined;
    VIN: string;
    odometer: string | number;
    exteriorColor?: string;
    interiorColor?: string;
    sellerName?: string;
    sellerRating: string | number;

    pickupLocation?: string;
    adjMMR?: string;
    vehicleLocation?: string;
    autocheckUrl?: string | undefined;
    MSRP?: string;

    frameDamage: string | undefined;
    priorPaint: string | undefined;
    comments: string;
    announcements: string;

    vehicleSpecification: VehicleSpecificationType;
    sellerProvidedOptions: string[];
}

export interface SearchCarCardType {
    vehicleUrl: string | undefined;

    model: string | null;
    price: number | null;
    photo: string | null;
    serialNumber: string | null;

    color: Array<string>;
    odometer: string | null;
    engine: string | null;
    transmission: string | null;
    mmr: string | null;

    sellerDisclosureUrl: string | null;
    conditionSearchUrl: string | null;
    conditionSearchRating?: number | null;

    pickup: string | null;
    seller: string | null;
    sellerRating: number | null;
    saleDate: string | null;
    salePlatform?: string | null;
}

export interface SearchCarType {
    vehicleUrl: string | undefined;
    cardData?: SearchCarCardType;
    vehicleData: SearchCarDataType | undefined;
    index?: number;
    isEmpty?: boolean;
    isDeleted?: boolean;
    isNew?: boolean;
}

export interface SearchCarWithId extends SearchCarType {
    id: string;
}

export interface SearchSchema {
    uid: string;
    email: string;

    status: QueueItemStatusEnum;
    photo: string | undefined;

    vehicleAmount: number;
    searchParams: QuerySearchParamsType;

    fetchedVehicleUrls: string[];
    createTime: number;
    updateTime: number;
}

export interface SearchSchemaWithId extends SearchSchema {
    id: string;
}

export enum SearchCollectionEnum {
    Cars ='cars'
}
