import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthContext = React.createContext<any>(
  [
    undefined,
    () => null,
    () => null,
  ],
);

export default AuthContext;
