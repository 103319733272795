import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    // flexWrap: 'nowrap',
  },
  asideMenu: {
    right: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  content: {
    display: 'flex',
    flexBasis: '83%',
    width: '100%',
  },
}));

export default useStyles;
