import React, {
  FC, useCallback, useMemo,
} from 'react';

import { useHistory } from 'react-router';
import ROUTES from '@/constants/routes';
import clsx from 'clsx';
import useStyles from './style';

type LinkType = {
  label: string;
  path: string;
}

type AsideMenuProps = {
  className?: string;
}

const AsideMenu: FC<AsideMenuProps> = ({ className }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleGo = useCallback((path: string) => () => {
    history.push(path);
  }, [history]);

  const memoLinks = useMemo<LinkType[]>(() => ([
    {
      label: 'Users',
      path: ROUTES.MANAGER_PANEL_USER,
    },
    {
      label: 'Queue',
      path: ROUTES.MANAGER_PANEL_QUEUE,
    },
    {
      label: 'Requests',
      path: ROUTES.MANAGER_PANEL_REQUEST_LIST,
    },
    {
      label: 'Cars of the day',
      path: ROUTES.MANAGER_PANEL_CAR_OF_THE_DAY_LIST,
    },
  ]), []);

  return (
    <div className={clsx(className, classes.root)}>
      {
        memoLinks.map((link) => (
          <button
            type="button"
            key={link.path}
            className={classes.link}
            onClick={handleGo(link.path)}
          >
            {link.label}
          </button>
        ))
      }
    </div>
  );
};

export default AsideMenu;
