/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
import firebase from 'firebase/app';
import { firestore } from '@/context/firebase';
import {
  QuerySchema, QuerySchemaWithId,
} from '@/types/query';
import APIBase from './base';

const queryRef = firestore.collection('query');

class QueryAPI extends APIBase {
  public async getAll(): Promise<QuerySchemaWithId[]> {
    const queryList = await queryRef
      .where('uid', '==', this.auth?.uid)
      .orderBy('status', 'asc')
      .orderBy('date', 'desc')
      .get();

    return queryList.docs.map((el) => ({ ...(el.data() as QuerySchema), id: el.id }));
  }

  public deleteById(id: string) {
    return queryRef.doc(id).delete();
  }

  public async deleteByIdList(idList: string[]) {
    return Promise.all(idList.map((id) => this.deleteById(id)));
  }

  public onSnapshot(
    queryId: string,
    cb: (snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => void,
  ) {
    return queryRef
      .doc(queryId)
      .onSnapshot(cb);
  }

  public onAllListSnapshot(
    cb: (
      (queryList: QuerySchemaWithId[]) => void
    ),
  ): () => void {
    if (this.auth?.uid) {
      return queryRef
        .orderBy('createTime', 'desc')
        .onSnapshot((snapshot) => {
          const queryList = snapshot.docs.map((doc) => ({
            ...(doc.data() as QuerySchema),
            id: doc.id,
          }));
          cb(queryList);
        });
    }
    return () => {};
  }
}

const queryAPI = new QueryAPI();

export default queryAPI;
