export enum QueueItemStatusEnum {
  Initialization = 'initialization',
  InQueue = 'in_queue',
  Processing = 'processing',
  Ready = 'ready',
  Expired = 'expired',
  NoData = 'no_data',
  Error = 'error',
  InitError = 'initialization_error'
}

export type IQueryExecuteType = {
  id: string;
}

export type IQueryCreateType = {
  searchId: string;
  vehicleToFetchAmount: number;
}

export type QuerySearchParamsType = {
  make: string;
  model: string;
  toYear: string;
  fromYear: string;
}

export interface QuerySchema {
  searchId: string;
  uid: string;
  email: string;
  status: QueueItemStatusEnum;
  vehicleToFetch: number;
  vehicleFetched: number;
  createTime: number;
}

export interface QuerySchemaWithId extends QuerySchema {
  id: string;
}
