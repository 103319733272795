/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import Button from '@material-ui/core/Button';

import ManagerPanelIcon from '@/assets/menu/manager_panel.svg';
import ProfileIcon from '@/assets/menu/profile.svg';
import FavoritesIcon from '@/assets/menu/favorites.svg';
import HistoryIcon from '@/assets/menu/history.svg';
import HowItWorksIcon from '@/assets/menu/how_it_works.svg';
import SearchIcon from '@/assets/menu/search.svg';
import SignInIcon from '@/assets/menu/sign_in.svg';
import CloseIcon from '@/assets/menu/close.svg';

import AuthContext from '@/context/auth';
import { UserRoleEnum } from '@/types/user';
import ROUTES from '@/constants/routes';
import _ from 'lodash';
import { MakeType } from '@/types/search';
import BurgerIcon from '@/assets/burger.svg';
import Modal from '@material-ui/core/Modal';
import Search from '@/app/Layout/components/Search';
import useStyles from './style';

type AsideProps = {
  makeData: MakeType[];
  onLogOut: { (): void };
  onGo: { (path: string): () => void };
}

const Aside: FC<AsideProps> = ({
  makeData,
  onLogOut,
  onGo,
}) => {
  const [isMenuOpen, setMenuOpenState] = useState<boolean>(false);
  const [isSearchOpen, setSearchOpenState] = useState<boolean>(false);

  const classes = useStyles();

  const [auth] = useContext(AuthContext);

  // const { width } = useResize();

  const handleOpenMenu = useCallback(() => {
    setMenuOpenState(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuOpenState(false);
  }, []);

  const handleOpenSearch = useCallback(() => {
    setSearchOpenState(true);
    setMenuOpenState(false);
  }, []);

  const handleCloseSearch = useCallback(() => {
    setSearchOpenState(false);
  }, []);

  return (
    <div className={classes.root}>
      <Button onClick={handleOpenMenu}>
        <img src={BurgerIcon} alt="" />
      </Button>
      <Modal
        open={isMenuOpen}
        onClose={handleCloseMenu}
        className={classes.modalContainer}
        aria-labelledby="aside-modal-title"
        aria-describedby="aside-modal-description"
      >
        <div className={classes.modal}>
          <div className={classes.modalContent}>
            <div className={classes.closeButton} onClick={handleCloseMenu}>
              <img src={CloseIcon} alt="" />
            </div>
            <div className={classes.controls}>
              {
                auth ? (
                  <>
                    <div className={classes.controlItem} onClick={onGo(ROUTES.HOW_IT_WORKS)}>
                      <img src={HowItWorksIcon} alt="" />
                      <span>How it works</span>
                    </div>
                    {
                      !!_.intersection(auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length && (
                        <div className={classes.controlItem} onClick={onGo(ROUTES.MANAGER_PANEL_USER)}>
                          <img src={ManagerPanelIcon} alt="" />
                          <span>Manager panel</span>
                        </div>
                      )
                    }
                    <div className={classes.controlItem} onClick={onGo(ROUTES.SEARCH_LIST)}>
                      <img src={HistoryIcon} alt="" />
                      <span>History</span>
                    </div>
                    <div className={classes.controlItem} onClick={onGo(ROUTES.FAVORITES)}>
                      <img src={FavoritesIcon} alt="" />
                      <span>Favorites</span>
                    </div>
                    <div className={classes.controlItem} onClick={handleOpenSearch}>
                      <img src={SearchIcon} alt="" />
                      <span>Search</span>
                    </div>
                    <div className={classes.controlItem} onClick={onGo(ROUTES.USER_PROFILE)}>
                      <img src={ProfileIcon} alt="" />
                      <span>Profile</span>
                    </div>
                    <div className={classes.controlItem} onClick={onLogOut}>
                      <img src={SignInIcon} alt="" />
                      <span>Sign out</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.controlItem} onClick={onGo(ROUTES.HOW_IT_WORKS)}>
                      <img src={HowItWorksIcon} alt="" />
                      <span>How it works</span>
                    </div>
                    <div className={classes.controlItem} onClick={onGo(ROUTES.SIGN_IN)}>
                      <img src={SignInIcon} alt="" />
                      <span>Sign In</span>
                    </div>
                  </>
                )
            }
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={isSearchOpen}
        onClose={handleCloseSearch}
        className={classes.modalContainer}
        aria-labelledby="aside-modal-title"
        aria-describedby="aside-modal-description"
      >
        <div className={classes.modalSearch}>
          <div className={classes.modalSearchContent}>
            <Search
              makeData={makeData}
              className={classes.modalSearchRoot}
              closePopup={handleCloseSearch}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Aside;
