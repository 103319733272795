import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      padding: '15px 0',
    },
  },
  modalContainer: {
    zIndex: 10000,
  },
  modal: {
    position: 'relative',
  },
  modalSearch: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSearchContent: {
    padding: 24,
    maxWidth: 360,
    backgroundColor: '#292934',
    // background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
    boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    width: 328,
    margin: 23,
    '&:focus': {
      outline: 'none',
    },
    '& > h4': {
      margin: 0,
      fontSize: 20,
      color: 'rgba(255, 255, 255, 0.87)',
      lineHeight: '24px',
      marginBottom: 16,
    },
    '& > p': {
      margin: 0,
      fontSize: 16,
      lineHeight: 1.3,
      letterSpacing: '0.25px',
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  modalSearchRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      marginLeft: 0,
      width: '100%',
      marginRight: 0,
      marginBottom: 24,
    },
    '& > button': {
      marginLeft: 0,
    },
  },
  modalContent: {
    position: 'fixed',
    height: '100%',
    width: '40vw',
    minWidth: 263,
    maxWidth: 350,
    top: 0,
    right: 0,
    backgroundColor: '#292934',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  closeButton: {
    margin: '64px 26px 19px 26px',
    cursor: 'pointer',
  },
  controls: {
    width: '100%',
  },
  controlItem: {
    border: 'none',
    color: 'white',
    fontSize: '18px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    textTransform: 'none',
    padding: '16px 26px',
    marginTop: 15,
    marginBottom: 10,
    backgroundColor: '#292934',
    width: '100%',
    cursor: 'pointer',
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginTop: 174,
      marginBottom: 0,
    },
    '&:hover': {
      backgroundColor: '#607D8B',
    },
    '& img': {
      marginRight: 10,
    },
    '& svg': {
      marginRight: 10,
    },
    '& span': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
