/* eslint-disable react/destructuring-assignment */
import React, {
  useMemo, useState, useContext, useEffect,
} from 'react';
import { useHistory } from 'react-router';

import { CircularProgress } from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import {
  NotificationContext, SearchContext, ModalContext,
} from '@/context';

// import makeData from '@/constants/make.json';
import { MakeType, ModelType } from '@/types/search';

import generateYearsList from '@/utils/functions/generateYearsList';

import clsx from 'clsx';
import ROUTES from '@/constants/routes';
import SearchAPI from '@/api/search';
import useStyles from './styles';

type SearchProps = {
  className?: string;
  closePopup?: () => void;
  makeData: MakeType[];
}

const Search: React.FC<SearchProps> = ({
  className,
  closePopup,
  makeData,
}) => {
  // useStyles
  const classes = useStyles();

  // useHistory
  const history = useHistory();

  // useContext
  const { setSuccess, setError } = useContext(NotificationContext);
  const [values, setValues] = useContext(SearchContext);
  const [, setModal] = useContext(ModalContext);

  // useState
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  // useMemo
  const fromYearList = useMemo(() => generateYearsList(1970), []);
  const toYearList = useMemo(
    () => fromYearList.filter((year: string) => year >= values.fromYear),
    [fromYearList, values.fromYear],
  );

  const modelList = useMemo(() => {
    const findedMake = makeData.find((make: MakeType) => values.make === make.value);

    return findedMake?.models || [];
  }, [makeData, values.make]);

  useEffect(() => {
    if (values.fromYear > values.toYear) {
      setValues({
        ...values,
        toYear: fromYearList[fromYearList.indexOf(values.fromYear)],
      });
    }
  }, [fromYearList, setValues, values]);

  useEffect(() => {
    if (modelList[0]) {
      setValues({
        ...values,
        model: modelList[0].value,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelList, setValues, values.make]);

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <div
      className={clsx(classes.formContainer, className)}
    >
      <FormControl className={classes.formControl}>
        <InputLabel id="from-year-label">Year Start</InputLabel>
        <Select
          labelId="from-year-label"
          id="from-year-select"
          value={values.fromYear}
          onChange={handleChange('fromYear')}
        >
          {fromYearList.map((year: string) => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="to-year-label">Year End</InputLabel>
        <Select
          labelId="to-year-label"
          id="to-year-select"
          value={values.toYear}
          onChange={handleChange('toYear')}
        >
          {toYearList.map((year: string) => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="make-label">Make</InputLabel>
        <Select
          labelId="make-label"
          id="make-select"
          value={values.make}
          onChange={handleChange('make')}
        >
          {
            makeData.map((make: MakeType) => (
              <MenuItem key={make.value} value={make.value}>{make.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="model-label">Model</InputLabel>
        <Select
          labelId="model-label"
          id="model-select"
          value={values.model}
          onChange={handleChange('model')}
          disabled={values.make === 'ALL'}
        >
          {
            modelList.map((model: ModelType) => (
              <MenuItem key={model.value} value={model.value}>{model.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <Button
        disabled={!values.make || !values.model || isSubmitted}
        onClick={handleSubmit}
        color="secondary"
        variant="contained"
        className={classes.button}
      >
        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        {!isLoading && <span>Search</span>}
      </Button>
    </div>
  );

  async function handleSubmit() {
    try {
      setSubmitted(true);
      setLoading(true);
      await SearchAPI.create(values);
      setSuccess('Search added in query success!');
      setSubmitted(false);
      history.push(ROUTES.SEARCH_LIST);
      setModal({
        title: 'Search in process',
        text: 'Please wait till our operator performs your request. Its may take up to 10-15min. Please leave a browser window open  or log  in back in 10-15 min to see the results',
      });
      closePopup?.();
    } catch (error) {
      setError(error.message);
      setSubmitted(false);
    } finally {
      setLoading(false);
    }
  }

  function handleChange(type: string): SelectInputProps['onChange'] {
    return ({ target }) => {
      setValues({
        ...values,
        [type]: target.value,
      });
      setSubmitted(false);
    };
  }
};

export default Search;
