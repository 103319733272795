/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { initialState } from '@/constants/modal';

const ModalContext = React.createContext<any>([
  initialState,
  () => null,
]);

export default ModalContext;
