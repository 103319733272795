import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => (
  {
    list: {
      maxHeight: '40vh',
      overflowY: 'auto',
    },
    searchOptions: {
      margin: theme.spacing(1),
    },
    columnCheckbox: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    iconPadding: {
      padding: '6px',
      [theme.breakpoints.up('xl')]: {
        padding: '12px',
      },
    },
  }
));

export default useStyles;
