import React, {
  FC, useContext, useEffect,
} from 'react';

import {
  NotificationContext, AuthContext,
} from '@/context';

import { useHistory } from 'react-router';
import { UserRoleEnum } from '@/types/user';
import _ from 'lodash';
import { Container } from '@material-ui/core';
import ROUTES from '@/constants/routes';
import AsideMenu from './components/AsideMenu';
import useStyles from './styles';

const ManagerLayout: FC = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  const [auth] = useContext(AuthContext);
  const { setError } = useContext(NotificationContext);

  useEffect(() => {
    if (!auth || !_.intersection(auth?.roles, [UserRoleEnum.Manager, UserRoleEnum.Admin]).length) {
      history.replace(ROUTES.HOME);
      setError('No permission');
    }
  }, [auth, history, setError]);

  return (
    <Container className={classes.root}>
      <AsideMenu className={classes.asideMenu} />
      <div className={classes.content}>
        { children }
      </div>
    </Container>
  );
};

export default ManagerLayout;
